/* Built In Imports */
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

/* External Imports */
import { Box, Link, Spinner } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */
import { consoleLog } from '@components/Utility/Shared/SharedService';
import { saveLikeAndBookmark } from 'services/wisdomService';

/**
 * Renders the Like Bookmark component
 *
 * @param {number} id - number
 * @param {boolean} isLiked
 * @param {boolean} isBookmarked
 * @param {string} lang language
 * @returns {ReactElement} Like Bookmark component
 */
export default function LikeBookmark({
  // id,
  isLiked,
  isBookmarked,
  lang,
  updateCards,
  uiFlex = 'row',
}) {
  const router = useRouter();
  const authCtx = useContext(AuthContext);
  const likeStatus = isLiked ? 'disable' : 'enable';
  const bookmarkStatus = isBookmarked ? 'disable' : 'enable';
  const [isLikeSpinner, setIsLikeSpinner] = useState(false);
  const [isBookmarkSpinner, setIsBookmarkSpinner] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const loginURL = `${config.FACADE_BASE_PATH}/login?request_url=${config.BASE_PATH}${router.asPath}&action=0`;

  useEffect(() => {
    setIsLoggedIn(authCtx.isLoggedIn);
  }, [isLoggedIn, isLikeSpinner, isBookmarkSpinner, authCtx.isLoggedIn]);

  const handleLikeOrBookmark = async (id, flag, status) => {
    setSpinnerOnLikeOrBookmark(flag, true);
    consoleLog(`is user logged in:::::::::::`, isLoggedIn);
    if (!isLoggedIn) {
      return window.location.assign(loginURL);
    } else {
      let res = await saveLikeAndBookmark(
        id,
        flag,
        status,
        lang,
        authCtx.facadeSessionId
      );
      consoleLog(`is like status:::::::::::`, res.status);
      if (res.status === 200) {
        updateCards(id, flag, likeStatus);
      }
      setSpinnerOnLikeOrBookmark(flag, false);
    }
  }; // click hanlder for like and bookmark

  const setSpinnerOnLikeOrBookmark = (flag, status) => {
    // if (flag === 'likes') {
      setIsLikeSpinner(status);
    // } else {
      // setIsLikeSpinner(status);
    // }
  }; // heart and bookmark spinner

  return (
    <Box display="flex" flexDirection={uiFlex} visibility={'hidden'}>
      <Box float="left" width={{ base: '44px', lg: '44px' }} m="0 0.2vw 0">
        <Box height="38px" width="34px" m="0 0.2vw 0">
          <Link className="loginLink loginsignup" data-action="0" href="#">
            <LazyLoadImageComponent
              cursor="pointer"
              // onClick={() => handleLikeOrBookmark(id, 'likes', likeStatus)}
              src={
                isLiked
                  ? `${config.imgPath}/d/46272/1663646707-heart_fill.svg`
                  : `${config.imgPath}/d/46272/1663646769-heart.svg`
              }
              height="24px"
              verticalAlign="middle"
              alt="heart"
              title="Heart"
            />
          </Link>
        </Box>
        <Spinner
          size="sm"
          pos="absolute"
          top="15px"
          display={isLikeSpinner ? 'inline-block' : 'none'}
        />
      </Box>

      <Box height="24px" width={{ base: '44px', lg: '44px' }} m="0 0.4vw 0">
        <Link className="loginLink loginsignup" data-action="0" href="#">
          <LazyLoadImageComponent
            cursor="pointer"
            src={
              isBookmarked
                ? `${config.imgPath}/d/46272/1663646858-bookmark_fill.svg`
                : `${config.imgPath}/d/46272/1663648408-bookmark.svg`
            }
            height="24px"
            verticalAlign="middle"
            alt="Bookmark"
            title="Bookmark"
            // onClick={() =>
            //   handleLikeOrBookmark(id, 'bookmarks', bookmarkStatus)
            // }
          />
        </Link>
        <Spinner
          size="sm"
          pos="absolute"
          top="15px"
          display={isBookmarkSpinner ? 'inline-block' : 'none'}
        />
      </Box>
    </Box>
  );
}

/* Built In Imports */

/* Internal Imports */
/* Components */
import config from '@config';
import { tryGetPreviewData } from 'utils';

/* Services */
import {
  consoleLog,
  getLocale,
} from '@components/Utility/Shared/SharedService';
import { filterBuildUrls } from 'services/commonService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';
import { getLatestBuild } from './commonService';

const isCache = false;
const API_PREFIX = '/content/fetchiso';
const API_CSR_PREFIX = '/content/fetchcsr';

export const getWisdomLandingPaths = async (level, url, notMatchText) => {
  const data = config.BUILD_URLS.WISDOM.enabled
    ? config.BUILD_URLS.WISDOM
    : await getLatestBuild(
        process.env.NEXT_PUBLIC_BUILD_ID ||
          process.env.env_var_wisdom_build_id ||
          process.env.env_var_build_id
      );
  const urlArr = filterBuildUrls('wisdom', level, url, notMatchText, data);
  return urlArr ? urlArr : [];
};

// wisdom api methods

export const fetchWisdomLandingPageData = async ({
  region,
  lang,
  topic = '',
  start = 0,
  limit = 12,
  contentType = '',
  sortby = '',
  slug = 'wisdom',
  previewData,
  localeURL,
}) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_CSR_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'wisdom',
          slug,
          // region,
          lang,
          topic,
          start,
          limit,
          contentType,
          sortby,
        },
      }
    );

    // For wisdom section, we are using only 1 common api to get locales which has locale url to landing wisdom page.
    // In case we are on child wisdom pages, we need to append the child url.
    localeURL &&
      localeURL !== '/topics' && // All topics page does not contain /type/ in its URL, hence we need to ignore it.
      pageData?.availableLocales &&
      (pageData.availableLocales = pageData.availableLocales.map(locale => {
        return {
          ...locale,
          value: locale.value.includes('/type/')
            ? // This should happen only first time and not every time this loop is run, hence the condition
              locale.value
            : `${locale.value}${localeURL}`,
        };
      }));
    return pageData;
  }
};

export const fetchBlogLandingPageData = async (
  region,
  lang,
  slug = 'blog',
  previewData
) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_CSR_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'wisdom',
          slug,
          region,
          lang,
        },
      }
    );
    return pageData;
  }
};

export const fetchBlogDetailData = async (
  region,
  lang,
  section,
  type,
  page = 'september-01-2021',
  previewData
) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    let url = `${
      config.FACADE_BASE_PATH
    }/content/fetchiso/content?format=json&sitesection=wisdom&slug=${section}/${type}/${encodeURI(
      page
    )}&region=${region}&lang=${lang}`;

    if (type === 'quotes') {
      url = `${config.FACADE_BASE_PATH}/content/fetchiso/content?format=json&sitesection=wisdom&slug=${section}/${type}/date/${page}&region=${region}&lang=${lang}`;
    }

    let pageData = {};
    try {
      pageData = await fetch(url);
      if (pageData.status !== 200) {
        pageData = null;
        consoleLog(`pageData not available ${url}`);
      } else {
        pageData =
          typeof pageData === 'string'
            ? {}
            : await (pageData && pageData.json());
      }
    } catch (error) {
      consoleLog('Error happened in fetchBlogDetailData!');
      consoleLog(error, 'error');
    }
    return pageData;
  }
};

export const fetchWisdomBlogSearchData = async ({
  region,
  language,
  topic,
  contentType,
  searchKey,
  start,
  slug,
  previewData,
}) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(
      previewData,
      getLocale(region, language, 'iso')
    );
  } else {
    let queryParams = {};
    if (start) {
      queryParams = {
        start,
        limit: 12,
      };
    }
    const pageData = await new IsoBackendHttpService().get(
      `${API_CSR_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'wisdom',
          region,
          lang: language,
          slug,
          topic,
          contentType,
          searchKey,
          ...queryParams,
        },
      }
    );
    return pageData;
  }
};

export const fetchAllTopicsList = async (region, lang, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const allTopics = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'wisdom',
          slug: 'wisdom/topics',
          region,
          lang,
        },
      }
    );
    return allTopics;
  }
};

export const fetchRelatedQuotes = async (
  region,
  lang,
  page,
  sitesection = 'wisdom',
  slug = 'quotes',
  previewData
) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const pageData = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection,
          slug: `${slug}/${page}`,
          // region,
          lang,
        },
      }
    );
    return pageData;
  }
};

export const saveLikeAndBookmark = async (uuid, Flag, status, lang, ssoId) => {
  const response = await fetch(
    `${config.FACADE_BASE_PATH}/content/likes-and-bookmarks-write/iso`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/text',
        'X-facadeId': ssoId,
      },
      method: 'POST',
      body: JSON.stringify({
        uuid: uuid,
        flag: Flag,
        status: status,
        language: lang,
      }),
    }
  );
  return response;
};

export const getLikesAndBookmarks = async (uuid = []) => {
  // const data = {
  //   likes: [
  //     '6e1f4549-70f2-4ff7-82b1-167c748ffd34',
  //     '88116c77-0f25-4b9e-a51f-2a87cec4a6b9',
  //     '0680f187-f359-4bba-b3e1-d4aae793d45e',
  //     'e6c40f87-cdd2-4a89-b110-772ac5e941f4',
  //   ],
  //   bookmarks: [
  //     '6e1f4549-70f2-4ff7-82b1-167c748ffd34',
  //     '88116c77-0f25-4b9e-a51f-2a87cec4a6b9',
  //     '30a2dd5a-96c3-4cc1-b7fc-210652316980',
  //     'e6c40f87-cdd2-4a89-b110-772ac5e941f4',
  //   ],
  // };
  const ids = { uuid: uuid };
  let data = await fetch(
    `${config.FACADE_BASE_PATH}/content/likes-and-bookmarks-read/iso`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(ids),
    }
  );
  data = await data.json();
  return data;
};

export const fetchAllBookmarkData = async data => {
  // let allBookMarkTopics = await fetch(
  //   `${config.FACADE_BASE_PATH}/content/bookmarks-list/iso?sortby=${data}`
  // );
  // allBookMarkTopics = await allBookMarkTopics.json();
  // return allBookMarkTopics;
  const allBookMarkTopics = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content/bookmarks-list/iso`,
    !isCache,
    {
      params: {
        sortby: data,
      },
    }
  );
  return allBookMarkTopics;
};

export const fetchTopicData = async (
  region,
  lang,
  topic = 'celebrity',
  previewData
) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  } else {
    const allTopics = await new IsoBackendHttpService().get(
      `${API_PREFIX}/content`,
      !isCache,
      {
        params: {
          format: 'json',
          sitesection: 'wisdom',
          slug: `topic/${topic}`,
          region,
          lang,
        },
      }
    );
    return allTopics;
  }
};
